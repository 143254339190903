<template>
  <div>
    <div class="m-2">
      <b-row>
        <!-- Per Page -->
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>Mostra</label>
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block mx-50"
          />
          <label>per pagina</label>
        </b-col>

      </b-row>
    </div>

    <b-table
      ref="refUsersInProjectsListTable"
      class="position-relative"
      :items="users"
      responsive
      :fields="fieldsUsersInProject"
      primary-key="id"
      :sort-by.sync="sortBy"
      :per-page="perPage"
      show-empty
      empty-text="No matching records found"
      :sort-desc.sync="isSortDirDesc"
    >
      <template #cell(user)="data">
        <b-media vertical-align="center">
          <template #aside>
            <b-avatar
              class="avatar"
              size="32"
              :src="data.item.avatar"
              variant="primary"
            />
          </template>
          <b-badge
            v-if="data.item.color"
            :style="'background-color:' + data.item.color"
            class="font-weight-bold text-nowrap"
          >
            {{ data.item.fullName }}
          </b-badge>
          <span
            v-else
            class="font-weight-bold text-nowrap"
          > {{ data.item.fullName }}
          </span>
          <div><strong>ID</strong> {{ data.item.id }}</div>
        </b-media>
      </template>

      <template #cell(matches_average)="data">
        <b-badge :variant="data.item.matches_average.average_of_right_answers ? 'success' : 'warning'">
          {{ data.item.matches_average.average_of_right_answers ? data.item.matches_average.average_of_right_answers.toFixed(1) : 'match insufficienti' }}
        </b-badge>
      </template>

      <template #cell(percentage_of_played_matches)="data">
        <b-badge :variant="data.item.matches_average.percentage_of_played_matches ? 'success' : 'warning'">
          {{ data.item.matches_average.percentage_of_played_matches.toFixed(2) }}%
        </b-badge>
        di {{ data.item.matches_average.user_matches }}
      </template>

      <template #cell(time_in_class)="data">
        {{ convertSecondsToHMS(data.item.accesses.time_in_class) }}
      </template>
    </b-table>

    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">dal {{ dataMetaUsersInProject.from }} a {{ dataMetaUsersInProject.to }} di {{ dataMetaUsersInProject.of }} utenti</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>
  </div>
</template>

<script>
import {
  BBadge,
  BTable,
  BAvatar,
  BMedia,
  BPagination,
  BRow,
  BCol,
} from 'bootstrap-vue'
import { computed, ref, watch } from '@vue/composition-api'
import useProject from '@/views/project/useProject'
import vSelect from 'vue-select'
import { convertSecondsToHMS } from '@core/utils/utils'

export default {
  components: {
    BBadge,
    BTable,
    BAvatar,
    BMedia,
    BPagination,
    BRow,
    BCol,

    vSelect,
  },
  props: {
    project: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const {
      sortBy,
      isSortDirDesc,
      fetchUsers,
    } = useProject()

    const refUsersInProjectsListTable = ref(null)

    const fieldsUsersInProject = [
      {
        key: 'user',
        label: 'User',
      },
      {
        key: 'email',
        label: 'Email',
      },
      {
        key: 'matches_average',
        label: 'Media risposte esatte',
      },
      {
        key: 'percentage_of_played_matches',
        label: '% Partite giocate',
      },
      {
        key: 'time_in_class',
        label: 'Tempo in classe (h:m:s)',
      },
    ]

    const users = ref([])
    const perPageOptions = [50, 100, 150, 200, 250, 300]
    const totalRows = ref(0)
    const perPage = ref(50)
    const currentPage = ref(1)
    const maxPages = ref(null)
    const onFetching = ref(false)

    const fetchNextUsers = async () => {
      onFetching.value = true
      fetchUsers({
        perPage: perPage.value,
        page: currentPage.value,
        project_id: props.project.id,
      })
        .then(response => {
          users.value = response.data.data.users
          // currentPage.value += 1
          maxPages.value = response.data.meta.last_page
          totalRows.value = response.data.meta.total
          // perPage.value = 15
        })
      onFetching.value = false
    }
    fetchNextUsers()

    watch([currentPage, perPage], () => {
      console.log(currentPage.value)
      fetchNextUsers()
    })

    const dataMetaUsersInProject = computed(() => {
      const localDataCount = refUsersInProjectsListTable.value ? refUsersInProjectsListTable.value.localItems.length : 0
      return {
        from: perPage.value * (currentPage.value - 1) + (localDataCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localDataCount,
        of: totalRows.value,
      }
    })

    return {
      refUsersInProjectsListTable,
      fieldsUsersInProject,

      dataMetaUsersInProject,
      sortBy,
      isSortDirDesc,
      perPageOptions,

      users,
      totalRows,
      perPage,
      currentPage,

      // handleScroll,

      convertSecondsToHMS,
    }
  },
}
</script>

<style scoped>

</style>
