<template>
  <!-- modal -->
  <b-modal
    :id="'modal-send-email'"
    :ref="'modal-send-email'"
    :title="'Invia le email'"
    ok-title="Invia ai partecipanti del progetto"
    ok-variant="danger"
    :ok-disabled="sendData.confirm !== 'conferma invio'"
    cancel-title="Chiudi"
    cancel-variant="outline-secondary"
    size="lg"
    centered
    @show="resetModal"
    @hidden="resetModal"
    @ok="handleOk"
  >
    <b-overlay
      id="overlay-background"
      :show="show"
      variant="transparent"
      :opacity="0.85"
      blur="2px"
      rounded="sm"
    >
      <validation-observer ref="sendEmailForm">
        <form
          ref="form"
          class="repeater-form"
          @submit.stop.prevent="validationForm"
        >
          <b-row>
            <b-col
              lg="12"
              md="12"
              sm="12"
              cols="12"
            >
              <validation-provider
                #default="validationContext"
                name="template"
                rules="required"
              >
                <b-form-group
                  label="Template"
                  label-for="name"
                  invalid-feedback="Obbligatorio"
                >
                  <v-select
                    v-model="sendData.template_id"
                    :state="getValidationState(validationContext)"
                    :reduce="val => val.value"
                    :options="resolveDataForVSelect()"
                    :clearable="false"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
            <template v-if="sendData.template_id">
              <b-col
                lg="12"
                md="12"
                sm="12"
                cols="12"
              >
                <validation-provider
                  #default="validationContext"
                  name="subject"
                  rules="required"
                >
                  <b-form-group
                    label="Oggetto"
                    label-for="oggetto"
                    invalid-feedback="Obbligatorio"
                  >
                    <b-form-input
                      id="oggetto"
                      v-model="sendData.fields.subject"
                      :state="getValidationState(validationContext)"
                      required
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                lg="12"
                md="12"
                sm="12"
                cols="12"
              >
                <validation-provider
                  #default="validationContext"
                  name="title"
                  rules="required"
                >
                  <b-form-group
                    label="Titolo"
                    label-for="titolo"
                    invalid-feedback="Obbligatorio"
                  >
                    <b-form-input
                      id="titolo"
                      v-model="sendData.fields.title"
                      :state="getValidationState(validationContext)"
                      required
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                lg="12"
                md="12"
                sm="12"
                cols="12"
              >
                <validation-provider
                  #default="validationContext"
                  name="subtitle"
                  rules=""
                >
                  <b-form-group
                    label="Sotto titolo"
                    label-for="sotto-titolo"
                    invalid-feedback="Obbligatorio"
                  >
                    <b-form-input
                      id="sotto-titolo"
                      v-model="sendData.fields.subtitle"
                      :state="getValidationState(validationContext)"
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                lg="12"
                md="12"
                sm="12"
                cols="12"
              >
                <validation-provider
                  #default="validationContext"
                  name="text1"
                  rules=""
                >
                  <b-form-group
                    label="Testo 1"
                    label-for="text1"
                    invalid-feedback="Obbligatorio"
                  >
                    <b-form-textarea
                      id="text1"
                      v-model="sendData.fields.text1"
                      placeholder="Testo 1"
                      rows="3"
                      :state="getValidationState(validationContext)"
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                lg="12"
                md="12"
                sm="12"
                cols="12"
              >
                <validation-provider
                  #default="validationContext"
                  name="text2"
                  rules=""
                >
                  <b-form-group
                    label="Testo 2"
                    label-for="text2"
                    invalid-feedback="Obbligatorio"
                  >
                    <b-form-textarea
                      id="text2"
                      v-model="sendData.fields.text2"
                      placeholder="Testo 2"
                      rows="3"
                      :state="getValidationState(validationContext)"
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                lg="12"
                md="12"
                sm="12"
                cols="12"
              >
                <b-form-checkbox
                  v-model="sendData.test_mode"
                  name="is-vertical-menu-collapsed"
                  class="mr-0"
                  switch
                  inline
                  @change="sendData.confirm = null"
                >
                  Modalità test? (digita una mail per testare l'invio)
                </b-form-checkbox>
              </b-col>
              <b-col
                v-if="sendData.test_mode"
                lg="12"
                class="mb-5 mt-3"
              >
                <validation-provider
                  #default="validationContext"
                  name="text2"
                  rules="required"
                >
                  <b-form-group
                    label="Email"
                    label-for="test_email"
                    invalid-feedback="Obbligatorio"
                  >
                    <!-- button on right -->
                    <b-input-group>
                      <b-form-input
                        id="test_email"
                        v-model="sendData.test_email"
                        placeholder="Email"
                        :state="getValidationState(validationContext)"
                        required
                      />
                      <b-input-group-append>
                        <b-button
                          variant="outline-primary"
                          @click="validationForm"
                        >
                          Invia email di test
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12">
                <hr>
              </b-col>
              <b-col
                v-if="!sendData.test_mode"
                lg="12"
                md="12"
                sm="12"
                cols="12"
                class="mb-3"
              >
                <validation-provider
                  #default="validationContext"
                  name="name"
                  rules="required"
                >
                  <b-form-group
                    label="Conferma invio (scrivi conferma invio)"
                    label-for="conferma-invio"
                    invalid-feedback="Obbligatorio"
                  >
                    <b-form-input
                      id="conferma-invio"
                      v-model="sendData.confirm"
                      placeholder="scrivi conferma invio"
                      :state="getValidationState(validationContext)"
                      required
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>
            </template>
          </b-row>
        </form>
      </validation-observer>
    </b-overlay>
  </b-modal>
</template>

<script>
import {
  BFormGroup, BModal, VBModal, BRow, BCol, BOverlay, BFormInput, BFormTextarea, BButton, BInputGroupAppend, BInputGroup, BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ref } from '@vue/composition-api'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import {
  required, alphaNum, integer, digits,
} from '@validations'
import vSelect from 'vue-select'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useProject from '@/views/project/useProject'

export default {
  name: 'SendEmailsModal',
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormGroup,
    BModal,
    BRow,
    BCol,
    BOverlay,
    BFormInput,
    BFormTextarea,
    BButton,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    vSelect,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    project: {
      type: [Object, Array],
      required: true,
    },
  },
  setup(props, { refs }) {
    // Use toast
    const toast = useToast()

    const blankSendData = {
      id: props.project.id,
      project_id: props.project.id,
      template_id: null,
      fields: {},
      confirm: '',
    }

    const sendData = ref(JSON.parse(JSON.stringify(blankSendData)))

    const resetSendData = () => {
      sendData.value = JSON.parse(JSON.stringify(blankSendData))
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetSendData)

    const {
      fetchTemplates,
      getTemplates,
      sendEmail,
    } = useProject()

    const show = ref(false)

    const onSubmit = () => {
      show.value = !show.value
      sendEmail(sendData.value)
        .then(response => {
          console.log(response)
          if (!sendData.value.test_mode) {
            refs['modal-send-email'].toggle('#toggle-btn')
          }
        })
        .catch(error => {
          Object.values(error.response.data)
            .forEach(message => {
              toast({
                component: ToastificationContent,
                props: {
                  title: message[0],
                  icon: 'AlertTriangleIcon',
                  variant: 'warning',
                },
              })
            })
        }).finally(() => { show.value = false })
    }

    fetchTemplates()

    const handleSubmit = () => {
      onSubmit()
    }
    const validationForm = () => {
      if (!sendData.value.test_mode) {
        sendData.value.test_email = null
      }
      refs.sendEmailForm.validate()
        .then(success => {
          if (success) {
            sendData.value.confirm = ''
            handleSubmit()
          }
        })
    }
    const resetModal = () => {
      fetchTemplates()
      // this.resetSendData()
    }
    const handleOk = bvModalEvt => {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      validationForm()
    }

    const resolveDataForVSelect = () => getTemplates()
      .map(data => ({
        label: `${data.name}`,
        value: data.id,
      }))

    return {
      resolveDataForVSelect,
      show,

      sendData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
      resetSendData,

      validationForm,
      resetModal,
      handleOk,
      handleSubmit,

      required,
      alphaNum,
      integer,
      digits,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
