<template>
  <div class="email-app-details">

    <!-- Email Header -->
    <div class="email-detail-header">

      <!-- Header: Left -->
      <div class="email-header-left d-flex align-items-center">
        <span class="go-back mr-1">
          <feather-icon
            :icon="$store.state.appConfig.isRTL ? 'ChevronRightIcon' : 'ChevronLeftIcon'"
            size="20"
            class="align-bottom"
            @click="$emit('close-class-view')"
          />
        </span>
        <h4 class="email-subject mb-0">
          {{ classViewData.name }}
        </h4>
      </div>

      <!-- Header: Right -->
      <div class="email-header-right ml-2 pl-1" />
    </div>

    <!-- Email Details -->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="email-scroll-area scroll-area mt-1"
    >
      <!-- Email Actions: Reply or Forward -->
      <b-row>
        <b-col
          md="6"
          sm="12"
        >
          <b-card>
            <div class="d-flex justify-content-between">
              <h5 class="mb-0">
                Numero di match:
                <b-badge variant="success">
                  {{ classViewData.number_of_matches }}
                </b-badge>
              </h5>
            </div>
          </b-card>
        </b-col>
        <b-col
          md="6"
          sm="12"
        >
          <b-card>
            <div class="d-flex justify-content-between">
              <h5 class="mb-0">
                Numero di match eseguiti:
                <b-badge variant="success">
                  {{ classViewData.number_of_matches_executed }}
                </b-badge>
              </h5>
            </div>
          </b-card>
        </b-col>
        <b-col
          md="6"
          sm="12"
        >
          <b-card>
            <div class="d-flex justify-content-between">
              <h5 class="mb-0">
                Durata domanda match:
                <b-badge variant="success">
                  {{ classViewData.match_duration }}
                </b-badge>
              </h5>
            </div>
          </b-card>
        </b-col>
        <b-col
          md="6"
          sm="12"
        >
          <b-card>
            <div class="d-flex justify-content-between">
              <h5 class="mb-0">
                Utenti nella classe:
                <b-badge
                  v-if="classViewData && classViewData.users"
                  variant="success"
                >
                  {{ classViewData.users.length }}
                </b-badge>
              </h5>
            </div>
          </b-card>
        </b-col>

        <b-col
          md="12"
        >
          <b-card no-body>
            <b-card-header>
              <b-card-title>
                Lista Utenti
              </b-card-title>
            </b-card-header>
            <b-table
              v-if="classViewData.users && classViewData.users.length"
              responsive
              :items="classViewData.users"
              :fields="fields"
              class="mb-0"
            >
              <template #cell(user)="data">
                <b-media vertical-align="center">
                  <template #aside>
                    <b-avatar
                      class="avatar"
                      size="32"
                      :src="data.item.avatar"
                      variant="primary"
                    />
                  </template>
                  <b-badge
                    v-if="data.item.color"
                    :style="'background-color:' + data.item.color"
                    class="font-weight-bold text-nowrap"
                  >
                    {{ data.item.fullName }}
                  </b-badge>
                  <span
                    v-else
                    class="font-weight-bold text-nowrap"
                  > {{ data.item.fullName }}
                  </span>
                  <div><strong>ID</strong> {{ data.item.id }}</div>
                </b-media>
              </template>
              <template #cell(company)="data">
                <b-badge
                  variant="info"
                  class="font-weight-bold text-nowrap"
                >
                  ID {{ data.item.company.id }}
                </b-badge>
                {{ data.item.company.name }}
              </template>
            </b-table>
            <b-card-body v-else>
              <div
                class="d-flex justify-content-between"
              >
                <h5 class="mb-0">
                  Nessuna utente trovato
                </h5>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col
          md="12"
        >
          <b-card no-body>
            <b-card-header>
              <b-card-title>
                Classifica classe ultimo match
              </b-card-title>
            </b-card-header>
            <b-table
              v-if="classViewData.rankings && classViewData.rankings[0] && classViewData.rankings[0].ranking_classes"
              responsive
              :items="classViewData.rankings[0].ranking_classes.find(classe => classe.id === classViewData.id).users"
              :fields="fieldsRanking"
              class="mb-0"
            >
              <template #cell(user)="data">
                <b-media vertical-align="center">
                  <template #aside>
                    <b-avatar
                      class="avatar"
                      size="32"
                      :src="data.item.avatar"
                      variant="primary"
                    />
                  </template>
                  <b-badge
                    v-if="data.item.color"
                    :style="'background-color:' + data.item.color"
                    class="font-weight-bold text-nowrap"
                  >
                    {{ data.item.fullName }}
                  </b-badge>
                  <span
                    v-else
                    class="font-weight-bold text-nowrap"
                  > {{ data.item.fullName }}
                  </span>
                  <div><strong>ID</strong> {{ data.item.id }}</div>
                </b-media>
              </template>

              <template #cell(points)="data">
                <b-badge variant="success">
                  {{ data.item.matches.points ? data.item.matches.points : '0' }}
                </b-badge>
              </template>
            </b-table>
            <b-card-body v-else>
              <div
                class="d-flex justify-content-between"
              >
                <h5 class="mb-0">
                  Nessuna classifica trovata
                </h5>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </vue-perfect-scrollbar>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BBadge, BCardTitle, BTable, BAvatar, BCardHeader, BCardBody, BMedia,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
  components: {

    // BSV
    BRow,
    BCol,
    BCard,
    BBadge,
    BCardTitle,
    BTable,
    BAvatar,
    BCardHeader,
    BCardBody,
    BMedia,

    // 3rd Party
    VuePerfectScrollbar,

  },
  directives: {
    Ripple,
  },
  props: {
    classViewData: {
      type: Object,
      required: true,
    },
  },
  methods: {
    openDocument(item) {
      if (item.type === 'file') {
        window.open(item.link, '_blank')
      } else {
        window.open(`https://player.vimeo.com/video/${item.link}?h=fa76efa6df&amp;title=0&amp;byline=0&amp;portrait=0&amp;speed=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`, '_blank')
      }
    },
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    const fields = [
      {
        key: 'user',
        label: 'Utente',
      },
      {
        key: 'email',
        label: 'Email',
      },
      {
        key: 'company',
        label: 'Azienda',
      },
    ]

    const fieldsRanking = [
      {
        key: 'user',
        label: 'Utente',
      },
      {
        key: 'email',
        label: 'Email',
      },
      {
        key: 'points',
        label: 'Points',
      },
    ]

    return {
      // UI
      perfectScrollbarSettings,
      fields,
      fieldsRanking,
    }
  },
}
</script>

<style>

</style>
