<template>
  <b-overlay
    id="overlay-background-project"
    :show="show"
    variant="transparent"
    :opacity="0.85"
    blur="2px"
    rounded="sm"
  >
    <b-card
      no-body
    >
      <b-card-header>
        <b-card-title>
          Lista {{ argument }}s
        </b-card-title>
        <b-button
          v-ripple.400="'rgba(40, 0, 111, 0.15)'"
          :disabled="!project.group_file[argument]"
          variant="primary"
          size="sm"
          @click="$bvModal.show(`modal-assign-file-${argument}-${project.id}`)"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-50"
          />
          <span class="align-middle">Aggiungi {{ argument }}</span>
        </b-button>
        <assign-file-modal
          :argument="argument"
          :project.sync="project"
          :files="getFilesByType(argument)"
          @refetch-data="$emit('refetch-data')"
        />
      </b-card-header>
      <b-list-group>
        <b-list-group-item class="d-flex justify-content-between align-items-center">
          <strong>ID</strong>
          <strong>Nome</strong>
          <strong>Tipo</strong>
          <strong>Aperture</strong>
          <strong>Visto per (h:m:s)</strong>
          <strong>Azione</strong>
        </b-list-group-item>

        <!-- draggable -->
        <draggable
          v-model="project.files[argument]"
          class="list-group list-group-flush cursor-move"
          tag="ul"
          @change="changeFilesOrder(argument, project.files[argument])"
        >
          <transition-group
            type="transition"
            name="flip-list"
          >

            <b-list-group-item
              v-for="(file, index) in project.files[argument]"
              :key="index"
              class="d-flex justify-content-between align-items-center"
            >
              <strong>{{ file ? file.id : '' }}</strong>
              <p>{{ file ? file.name : '' }}</p>
              <b-badge
                :variant="typeResolver(file ? file.type : '')"
                class="badge"
              >
                {{ file ? file.type : '' }}
              </b-badge>
              <div class="d-flex flex-column">
                {{ file.opened || 0 }}
              </div>
              <div class="d-flex flex-column">
                {{ convertSecondsToHMS(file.time) }}
              </div>
              <div class="d-flex flex-column">
                <b-button
                  v-ripple.400="'rgba(40, 0, 111, 0.15)'"
                  variant="flat-secondary"
                  size="sm"
                  @click="openDocument(handleLinks(file)[0])"
                >
                  <feather-icon
                    icon="NavigationIcon"
                    class="mr-50"
                  />
                  <span class="align-middle">Vedi</span>
                </b-button>

                <b-button
                  v-ripple.400="'rgba(40, 0, 111, 0.15)'"
                  variant="flat-danger"
                  size="sm"
                  @click="removeFile(argument, file)"
                >
                  <feather-icon
                    icon="XIcon"
                    class="mr-50"
                  />
                  <span class="align-middle">Rimuovi</span>
                </b-button>
              </div>
            </b-list-group-item>

          </transition-group>
        </draggable>
      </b-list-group>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BOverlay, BCard, BCardHeader, BCardTitle, BButton, BListGroup, BListGroupItem, BBadge,
} from 'bootstrap-vue'
import AssignFileModal from '@/views/project/project-show/AssignFileModal.vue'
import Ripple from 'vue-ripple-directive'
import draggable from 'vuedraggable'
import { computed, ref } from '@vue/composition-api'
import useFileList from '@/services/useFileList'
import useProject from '@/views/project/useProject'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
import { convertSecondsToHMS, handleLinks, openDocument } from '@core/utils/utils'

export default {
  components: {
    BOverlay,
    BCard,
    BCardHeader,
    BCardTitle,
    BButton,
    BListGroup,
    BListGroupItem,
    BBadge,

    AssignFileModal,

    draggable,
  },
  directives: {
    Ripple,
  },
  props: {
    project: {
      type: [Object, Array],
      required: true,
    },
    argument: {
      type: String,
      required: true,
    },
  },
  methods: { convertSecondsToHMS },

  setup(props, { emit }) {
    // Use toast
    const toast = useToast()

    const show = ref(false)

    const {
      typeResolver,
      refetchData,
    } = useProject()

    const {
      perPage,
      getGroup,
      getListFile,
    } = useFileList()
    perPage.value = null
    getGroup.value = null

    const getFiles = computed(() => getListFile())

    const getFilesByType = type => getFiles.value.filter(file => file.file_type === type)

    const updateVisibleFiles = (type, visibleFiles) => {
      show.value = !show.value
      // console.log('payload :>>', { ...props.project, file_type: type, visible_files: visibleFiles })
      store.dispatch('project/updateProject', { id: props.project.id, file_type: type, visible_files: visibleFiles })
        .then(() => {
          emit('refetch-data')
          show.value = false
        })
        .catch(error => {
          Object.values(error.response.data)
            .forEach(message => {
              toast({
                component: ToastificationContent,
                props: {
                  title: message[0],
                  icon: 'AlertTriangleIcon',
                  variant: 'warning',
                },
              })
            })
          show.value = false
        })
    }

    const prepareVisibleFilesToSend = visibleFiles => {
      // dichiarato come oggetto perché non si può inviare una array che non parte da chiave 0 con axios
      const files = {}
      Object.keys(visibleFiles).forEach(types => {
        visibleFiles[types].forEach((fileId, index) => {
          files[fileId] = { order: index }
        })
      })
      return files
    }

    const changeFilesOrder = (type, newOrder) => {
      const visibleFiles = {}
      Object.keys(props.project.files).forEach(types => {
        visibleFiles[types] = props.project.files[types].map(({ id }) => id).reverse()
      })
      visibleFiles[type] = newOrder.map(({ id }) => id).reverse()

      updateVisibleFiles(type, prepareVisibleFilesToSend(visibleFiles))
    }

    const removeFile = (type, file) => {
      const visibleFiles = {}
      Object.keys(props.project.files).forEach(types => {
        visibleFiles[types] = props.project.files[types].map(({ id }) => id).reverse()
      })
      visibleFiles[type].splice(visibleFiles[type].indexOf(file.id), 1)

      updateVisibleFiles(type, prepareVisibleFilesToSend(visibleFiles))
    }

    return {
      show,

      typeResolver,
      refetchData,

      getFilesByType,

      changeFilesOrder,
      removeFile,

      handleLinks,
      openDocument,
    }
  },
}

</script>

<style scoped>

</style>
