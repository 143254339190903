<template>
  <!-- modal -->
  <b-modal
    :id="'modal-assign-quiz-'+match.id"
    :ref="'modal-assign-quiz-'+match.id"
    :title="'Assegna il quiz al match '+match.id"
    ok-title="Salva"
    cancel-title="Chiudi"
    cancel-variant="outline-secondary"
    size="md"
    centered
    @show="resetModal"
    @hidden="resetModal"
    @ok="handleOk"
  >
    <b-overlay
      id="overlay-background"
      :show="show"
      variant="transparent"
      :opacity="0.85"
      blur="2px"
      rounded="sm"
    >
      <validation-observer ref="editTrainingForm">
        <form
          ref="form"
          class="repeater-form"
          @submit.stop.prevent="validationForm"
        >

          <b-row>
            <b-col
              lg="12"
              md="12"
              sm="12"
              cols="12"
            >
              <validation-provider
                #default="validationContext"
                name="name"
                rules="required"
              >
                <b-form-group
                  label="Nome"
                  label-for="name"
                  invalid-feedback="Obbligatorio"
                >
                  <v-select
                    v-model="sendData.quiz_id"
                    :state="getValidationState(validationContext)"
                    :reduce="val => val.value"
                    :options="resolveDataForVSelect()"
                    :clearable="false"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </form>
      </validation-observer>
    </b-overlay>
  </b-modal>
</template>

<script>
import {
  BFormGroup, BModal, VBModal, BRow, BCol, BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ref } from '@vue/composition-api'
import store from '@/store'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import {
  required, alphaNum, integer, digits,
} from '@validations'
import vSelect from 'vue-select'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'AssignQuizModal',

  components: {
    ValidationProvider,
    ValidationObserver,
    BFormGroup,
    BModal,
    BRow,
    BCol,
    BOverlay,
    vSelect,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    match: {
      type: [Object, Array],
      required: true,
    },
    project: {
      type: [Object, Array],
      required: true,
    },
    quizzes: {
      type: [Object, Array],
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      integer,
      digits,
    }
  },
  watch: {
    match(newVal) { // watch it
      // console.log('Prop changed: ', newVal, ' | was: ', oldVal)
      this.sendData.quiz_id = newVal.quiz ? newVal.quiz.id : null
    },
  },
  methods: {

    validationForm() {
      this.$refs.editTrainingForm.validate()
        .then(success => {
          if (success) {
            this.handleSubmit()
          }
        })
    },
    resetModal() {
      // this.resetSendData()
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.validationForm()
    },
    handleSubmit() {
      this.onSubmit()
    },
  },
  setup(props, { emit, refs }) {
    // Use toast
    const toast = useToast()

    const show = ref(false)

    const blankSendData = {
      id: props.match.id,
      quiz_id: props.match.quiz ? props.match.quiz.id : null,
    }

    const sendData = ref(JSON.parse(JSON.stringify(blankSendData)))
    const resetSendData = () => {
      sendData.value = JSON.parse(JSON.stringify(blankSendData))
    }

    const onSubmit = () => {
      show.value = !show.value

      store.dispatch('project/updateMatch', sendData.value)
        .then(() => {
          emit('refetch-data')
          refs[`modal-assign-quiz-${props.match.id}`].toggle('#toggle-btn')
          show.value = false
        })
        .catch(error => {
          Object.values(error.response.data)
            .forEach(message => {
              toast({
                component: ToastificationContent,
                props: {
                  title: message[0],
                  icon: 'AlertTriangleIcon',
                  variant: 'warning',
                },
              })
            })
          show.value = false
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetSendData)

    const resolveDataForVSelect = () => props.quizzes.filter(quiz => quiz.group_quiz_id === props.project.group_quiz.id).map(data => ({ label: `${data.name}`, value: data.id }))

    return {
      resolveDataForVSelect,
      show,

      sendData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
      resetSendData,
    }
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
