<template>
  <div style="height: inherit">
    <div class="email-app-list">
      <vue-perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="email-user-list scroll-area"
      >
        <ul class="email-media-list">
          <b-media
            v-for="classe in classes"
            :key="classe.id"
            tag="li"
            no-body
            @click="updateViewData(classe)"
          >

            <b-media-aside class="media-left mr-50">
              <div class="user-action">
                <div class="email-favorite">
                  {{ classe.id }}
                </div>
              </div>
            </b-media-aside>

            <b-media-body>
              <div class="mail-details">
                <div class="mail-items">
                  <h5 class="mb-25">
                    {{ classe.name }}
                  </h5>
                  <span class="text-truncate">Numero di match previsti: {{ classe.number_of_matches }}</span>
                </div>
                <div class="mail-meta-item">
                  <span class="mail-date">Ultimo di aggiornamento: {{ classe.updated_at }}</span>
                </div>
              </div>

              <div class="mail-message">
                <p class="text-truncate mb-0">
                  Durata domande match: {{ classe.match_duration }}
                </p>
              </div>
            </b-media-body>
          </b-media>
        </ul>
        <div
          class="no-results"
        >
          <h5>Nessuna classe trovata</h5>
        </div>
      </vue-perfect-scrollbar>
    </div>
    <!-- Email View/Detail -->
    <class-view
      :class="{'show': showClassView}"
      :class-view-data.sync="classViewData"
      @refetch-data="$emit('refetch-data')"
      @close-class-view="showClassView = false"
    />
  </div>
</template>

<script>
import { BMedia, BMediaAside, BMediaBody } from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import ClassView from '@/views/project/project-show/ClassView.vue'
import { ref } from '@vue/composition-api'
import useProject from '@/views/project/useProject'

export default {
  name: 'ClassesList',
  components: {
    ClassView,
    BMedia,
    BMediaAside,
    BMediaBody,

    // 3rd Party
    VuePerfectScrollbar,
  },
  props: {
    classes: {
      type: Array,
      required: true,
    },
  },
  watch: {
    classes(newVal) {
      if (this.classViewData.id !== undefined) {
        this.classViewData = newVal.find(classe => classe.id === this.classViewData.id)
      }
    },
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    const {
      fetchClass,
    } = useProject()

    const showClassView = ref(false)
    const classViewData = ref({})
    const updateViewData = data => {
      fetchClass(data.id).then(response => {
        classViewData.value = response.data.data
      })

      // classViewData.value = data
      showClassView.value = true
    }

    return {
      // UI
      perfectScrollbarSettings,
      showClassView,
      classViewData,
      updateViewData,
    }
  },
}
</script>

<style scoped>

</style>
