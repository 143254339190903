<template>
  <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
  <div
    style="height: inherit"
  >
    <div
      class="body-content-overlay"
      :class="{'show': mqShallShowLeftSidebar}"
      @click="mqShallShowLeftSidebar = false"
    />

    <!-- Project List -->
    <div class="email-app-list">

      <!-- App Searchbar Header -->
      <!--      <div class="app-fixed-search d-flex align-items-center">-->

      <!-- Toggler -->
      <!--        <div class="sidebar-toggle d-block d-lg-none ml-1">-->
      <!--          <feather-icon-->
      <!--            icon="MenuIcon"-->
      <!--            size="21"-->
      <!--            class="cursor-pointer"-->
      <!--            @click="mqShallShowLeftSidebar = true"-->
      <!--          />-->
      <!--        </div>-->

      <!-- Searchbar -->
      <!--        <div class="d-flex align-content-center justify-content-between w-100">-->
      <!--          <b-input-group class="input-group-merge">-->
      <!--            <b-input-group-prepend is-text>-->
      <!--              <feather-icon-->
      <!--                icon="SearchIcon"-->
      <!--                class="text-muted"-->
      <!--              />-->
      <!--            </b-input-group-prepend>-->
      <!--            <b-form-input-->
      <!--              :value="searchQuery"-->
      <!--              placeholder="Search email"-->
      <!--              @input="updateRouteQuery"-->
      <!--            />-->
      <!--          </b-input-group>-->
      <!--        </div>-->

      <!--      </div>-->

      <div
        v-if="loading"
        class="d-flex justify-content-center mb-1 mt-5"
      >
        <b-spinner label="Loading..." />
      </div>

      <!-- Matches List -->
      <project-info
        v-show="$router.currentRoute.params.progetto && !$router.currentRoute.params.folder && !loading"
        v-if="project"
        :project.sync="project"
        @refetch-data="fetchProject"
      />

      <!-- Classes List -->
      <classes-list
        v-show="$router.currentRoute.params.folder && $router.currentRoute.params.folder === 'classi' && !loading"
        v-if="project && project.classes"
        :classes.sync="project.classes"
        @refetch-data="fetchProject"
      />

      <!-- Matches List -->
      <matches-list
        v-show="$router.currentRoute.params.folder && $router.currentRoute.params.folder === 'matches' && !loading"
        v-if="project && project.matches"
        :matches.sync="project.matches"
        :project.sync="project"
        @refetch-data="fetchProject"
      />
    </div>

    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-left">
      <project-left-sidebar
        :shall-show-email-compose-modal.sync="shallShowEmailComposeModal"
        :projects-meta="projectsMeta"
        :class="{'show': mqShallShowLeftSidebar}"
        @close-left-sidebar="mqShallShowLeftSidebar = false"
      />
    </portal>
  </div>
</template>

<script>
import store from '@/store'
import {
  ref, computed, watch,
  // ref, watch, computed, onUnmounted,
} from '@vue/composition-api'
import {
  BSpinner,
} from 'bootstrap-vue'
import { filterTags, formatDateToMonthShort } from '@core/utils/filter'
import { useRouter } from '@core/utils/utils'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import ProjectLeftSidebar from '@/views/project/ProjectLeftSidebar.vue'
import ClassesList from '@/views/project/project-show/ClassesList.vue'
import MatchesList from '@/views/project/project-show/MatchesList.vue'
import ProjectInfo from '@/views/project/project-show/ProjectInfo.vue'
import useProject from './useProject'

export default {
  components: {
    ProjectInfo,
    MatchesList,
    ClassesList,

    BSpinner,

    // App SFC
    ProjectLeftSidebar,
  },
  setup() {
    const { route, router } = useRouter()
    const { resolveLabelColor } = useProject()

    const loading = ref(false)

    // Route Params
    const routeParams = computed(() => route.value.params)

    // Emails & EmailsMeta
    const project = ref([])
    const projectsMeta = ref({})

    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    // Search Query
    const routeQuery = computed(() => route.value.query.q)
    const searchQuery = ref(routeQuery.value)
    watch(routeQuery, val => {
      searchQuery.value = val
    })
    // eslint-disable-next-line no-use-before-define
    watch(searchQuery, () => fetchProject())
    const updateRouteQuery = val => {
      const currentRouteQuery = JSON.parse(JSON.stringify(route.value.query))

      if (val) currentRouteQuery.q = val
      else delete currentRouteQuery.q

      router.replace({ name: route.name, query: currentRouteQuery })
    }

    const fetchProject = () => {
      loading.value = true
      store.dispatch('project/fetchProject', router.currentRoute.params.progetto)
        .then(response => {
          store.commit('project/setCurrentProject', response.data.data)
          project.value = response.data.data
        })
        .finally(() => {
          loading.value = false
        })
    }
    fetchProject()

    watch(routeParams, () => {
      fetchProject()
    })

    // ------------------------------------------------
    // Mail Selection
    // ------------------------------------------------
    const selectedEmails = ref([])
    const toggleSelectedMail = mailId => {
      const mailIndex = selectedEmails.value.indexOf(mailId)

      if (mailIndex === -1) selectedEmails.value.push(mailId)
      else selectedEmails.value.splice(mailIndex, 1)
    }
    // ? Watcher to reset selectedEmails is somewhere below due to watch dependecy fullfilment

    // ------------------------------------------------
    // Mail Actions
    // ------------------------------------------------
    // const toggleStarred = email => {
    //   store.dispatch('project/updateProject', {
    //     emailIds: [email.id],
    //     dataToUpdate: { isStarred: !email.isStarred },
    //   }).then(() => {
    //     // eslint-disable-next-line no-param-reassign
    //     email.isStarred = !email.isStarred
    //   })
    // }

    // const moveSelectedEmailsToFolder = folder => {
    //   store.dispatch('project/updateProject', {
    //     emailIds: selectedEmails.value,
    //     dataToUpdate: { folder },
    //   })
    //     .then(() => { fetchProject() })
    //     .finally(() => { selectedEmails.value = [] })
    // }

    // ------------------------------------------------
    // Email Details
    // ------------------------------------------------
    const showEmailDetails = ref(false)
    const emailViewData = ref({})
    const opendedEmailMeta = computed(() => {
      const openedEmailIndex = project.value.findIndex(e => e.id === emailViewData.value.id)
      return {
        hasNextEmail: Boolean(project.value[openedEmailIndex + 1]),
        hasPreviousEmail: Boolean(project.value[openedEmailIndex - 1]),
      }
    })

    // * If someone clicks on filter while viewing detail => Close the email detail view
    watch(routeParams, () => {
      showEmailDetails.value = false
    })

    // * Watcher to reset selectedEmails
    // ? You can also use showEmailDetails (instead of `emailViewData`) but it will trigger execution twice in this case
    // eslint-disable-next-line no-use-before-define
    watch([emailViewData, routeParams], () => {
      selectedEmails.value = []
    })

    // Compose
    const shallShowEmailComposeModal = ref(false)

    // Left Sidebar Responsiveness
    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()

    return {
      loading,

      // UI
      perfectScrollbarSettings,

      // Emails & EmailsMeta
      project,
      projectsMeta,

      // Mail Selection
      selectedEmails,
      toggleSelectedMail,

      // Mail Actions
      // toggleStarred,
      // moveSelectedEmailsToFolder,

      // Email Details
      showEmailDetails,
      emailViewData,
      opendedEmailMeta,

      // Search Query
      searchQuery,
      updateRouteQuery,

      // UI Filters
      filterTags,
      formatDateToMonthShort,

      // useProject
      resolveLabelColor,

      // Compose
      shallShowEmailComposeModal,

      // Left Sidebar Responsiveness
      mqShallShowLeftSidebar,

      fetchProject,
    }
  },
}
</script>

<style lang="scss" scoped>

</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-email.scss";
</style>
