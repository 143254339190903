<template>
  <div class="email-app-details">

    <!-- Email Header -->
    <div class="email-detail-header">

      <!-- Header: Left -->
      <div class="email-header-left d-flex align-items-center">
        <span class="go-back mr-1">
          <feather-icon
            :icon="$store.state.appConfig.isRTL ? 'ChevronRightIcon' : 'ChevronLeftIcon'"
            size="20"
            class="align-bottom"
            @click="$emit('close-view')"
          />
        </span>
        <h4 class="email-subject mb-0">
          Match {{ viewData.id }}
        </h4>
      </div>

      <!-- Header: Right -->
      <div class="email-header-right ml-2 pl-1" />
    </div>

    <!-- Email Details -->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="email-scroll-area scroll-area mt-1"
    >
      <!-- Email Actions: Reply or Forward -->
      <b-card>
        <b-tabs>
          <b-tab title="Info">
            <b-row>
              <b-col
                md="6"
                sm="12"
              >
                <b-card
                  border-variant="primary"
                  bg-variant="transparent"
                  class="shadow-none"
                >
                  <div class="d-flex justify-content-between">
                    <h5 class="mb-0">
                      Scarica i reports:
                    </h5>
                    <b-button
                      variant="flat-danger"
                      size="sm"
                      :disabled="loading"
                      @click="handleDownloadReport"
                    >
                      <b-spinner
                        v-if="loading"
                        small
                      />
                      <feather-icon
                        v-else
                        icon="DownloadIcon"
                        class="mr-50"
                      />
                      <span>Scarica</span>
                    </b-button>
                  </div>
                </b-card>
              </b-col>
              <b-col
                md="6"
                sm="12"
              >
                <b-card
                  border-variant="primary"
                  bg-variant="transparent"
                  class="shadow-none"
                >
                  <div class="d-flex justify-content-between">
                    <h5 class="mb-0">
                      Domande match:
                      <b-badge :variant="viewData.quiz ? 'success' : 'warning'">
                        {{ viewData.quiz ? viewData.quiz.quiz.length : 'Quiz non associato' }}
                      </b-badge>
                    </h5>
                  </div>
                </b-card>
              </b-col>
              <b-col
                md="6"
                sm="12"
              >
                <b-card
                  border-variant="primary"
                  bg-variant="transparent"
                  class="shadow-none"
                >
                  <div class="d-flex justify-content-between">
                    <h5 class="mb-0">
                      Inizio Match:
                      <b-badge
                        v-if="viewData.start_times"
                        variant="success"
                      >
                        {{ viewData.start_times }}
                      </b-badge>
                      <b-badge
                        v-else
                        variant="warning"
                      >Non assegnato
                      </b-badge>
                    </h5>

                    <b-button
                      variant="flat-primary"
                      size="sm"
                      @click="$bvModal.show('modal-assign-start-time-'+viewData.id)"
                    >
                      <span> {{ viewData.start_times ? 'Riassegna' : 'Assegna' }}</span>
                    </b-button>
                  </div>
                </b-card>
              </b-col>
              <b-col
                md="6"
                sm="12"
              >
                <b-card
                  border-variant="primary"
                  bg-variant="transparent"
                  class="shadow-none"
                >
                  <div class="d-flex justify-content-between">
                    <h5 class="mb-0">
                      Stato:
                      <b-badge
                        v-if="viewData.ended_at"
                        variant="success"
                      >
                        Eseguito
                        <feather-icon icon="CheckIcon" />
                      </b-badge>
                      <b-badge
                        v-else-if="viewData.started_at"
                        variant="warning"
                      >
                        In corso
                        <feather-icon icon="MinusIcon" />
                      </b-badge>
                      <b-badge
                        v-else
                        variant="danger"
                      >
                        Non ancora iniziato
                        <feather-icon icon="XIcon" />
                      </b-badge>
                    </h5>
                  </div>
                </b-card>
              </b-col>
              <b-col
                md="6"
                sm="12"
              >
                <b-card
                  border-variant="primary"
                  bg-variant="transparent"
                  class="shadow-none"
                >
                  <div class="d-flex justify-content-between">
                    <h5 class="mb-0">
                      Ultimo aggiornamento:
                      <b-badge
                        variant="success"
                      >
                        {{ viewData.updated_at }}
                      </b-badge>
                    </h5>
                  </div>
                </b-card>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab title="Domande">

            <b-list-group
              v-if="viewData.quiz"
            >
              <b-list-group-item
                v-for="domanda in viewData.quiz.quiz"
                :key="viewData.id+''+viewData.quiz.id+''+domanda.id"
                class="flex-column align-items-start"
              >
                <div class="d-flex w-100 justify-content-between">
                  <h5
                    class="mb-1"
                  >
                    {{ domanda.question }}
                  </h5>
                </div>
                <b-card-text
                  v-if="domanda.answers"
                  class="mb-1"
                >
                  <b-list-group>
                    <b-list-group-item
                      v-for="answer in domanda.answers"
                      :key="viewData.id+''+viewData.quiz.id+''+domanda.id+''+answer.id"
                      class="d-flex justify-content-between align-items-center"
                    >
                      <span>{{ answer.text }}</span>
                      <b-badge
                        :variant="answer.correct ? 'success' : 'danger'"
                        pill
                        class="badge-round"
                      >
                        <feather-icon :icon="answer.correct ? 'CheckIcon' : 'XIcon'" />
                      </b-badge>
                    </b-list-group-item>
                  </b-list-group>
                </b-card-text>
              </b-list-group-item>

            </b-list-group>
            <div
              v-else
              class="d-flex justify-content-between"
            >
              <h5 class="mb-0">
                Nessuna classifica trovata
              </h5>
            </div>

          </b-tab>
          <b-tab title="Classifica">
            <b-table
              v-if="viewData.ranking_users && viewData.ranking_users.length"
              responsive
              :items="viewData.ranking_users"
              :fields="fields"
              class="mb-0"
            >
              <template #cell(user)="data">
                <b-media vertical-align="center">
                  <template #aside>
                    <b-avatar
                      class="avatar"
                      size="32"
                      :src="data.item.avatar"
                      variant="primary"
                    />
                  </template>
                  <b-badge
                    v-if="data.item.color"
                    :style="'background-color:' + data.item.color"
                    class="font-weight-bold text-nowrap"
                  >
                    {{ data.item.fullName }}
                  </b-badge>
                  <span
                    v-else
                    class="font-weight-bold text-nowrap"
                  > {{ data.item.fullName }}
                  </span>
                  <div><strong>ID</strong> {{ data.item.id }}</div>
                </b-media>
              </template>

              <template #cell(points)="data">
                <b-badge variant="success">
                  {{ data.item.matches.points ? data.item.matches.points : '0' }}
                </b-badge>
              </template>

              <template #cell(played)="data">
                <b-badge :variant="data.item.matches.quiz ? 'success' : 'warning'">
                  {{ data.item.matches.quiz ? 'SI' : 'NO' }}
                </b-badge>
              </template>

            </b-table>
            <div
              v-else
              class="d-flex justify-content-between"
            >
              <h5 class="mb-0">
                Nessuna classifica trovata
              </h5>
            </div>
          </b-tab>
        </b-tabs>
      </b-card>
    </vue-perfect-scrollbar>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BBadge,
  BListGroup,
  BListGroupItem,
  BCardText,
  BTable,
  BButton,
  BAvatar,
  BMedia,
  BTabs,
  BTab,
  BSpinner,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import useProject from '@/views/project/useProject'
import { ref } from '@vue/composition-api'

export default {
  components: {
    // BSV
    BRow,
    BCol,
    BCard,
    BBadge,
    BListGroup,
    BListGroupItem,
    BCardText,
    BTable,
    BButton,
    BAvatar,
    BMedia,
    BTabs,
    BTab,
    BSpinner,

    // 3rd Party
    VuePerfectScrollbar,

  },
  directives: {
    Ripple,
  },
  props: {
    viewData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    const loading = ref(false)

    const {
      downloadReport,
    } = useProject()

    const handleDownloadReport = () => {
      loading.value = true
      downloadReport({ match_id: props.viewData.id }).finally(() => {
        loading.value = false
      })
    }

    const fields = [
      {
        key: 'user',
        label: 'User',
      },
      {
        key: 'email',
        label: 'Email',
      },
      {
        key: 'points',
        label: 'Points',
      },
      {
        key: 'played',
        label: 'Partecipato',
      },
    ]

    return {
      loading,

      handleDownloadReport,
      // UI
      perfectScrollbarSettings,
      fields,
    }
  },
}
</script>

<style>

</style>
