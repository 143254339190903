<template>
  <div style="height: inherit">
    <div
      class="email-app-details show"
    >

      <!-- Email Header -->
      <!--      <div class="email-detail-header">-->

      <!-- Header: Left -->
      <!--        <div class="email-header-left d-flex align-items-center">-->
      <!--          <h4 class="email-subject mb-0">-->
      <!--            Progetto {{ project.name }}-->
      <!--          </h4>-->
      <!--        </div>-->

      <!-- Header: Right -->
      <!--        <div class="email-header-right ml-2 pl-1" />-->
      <!--      </div>-->

      <!-- Email Details -->
      <vue-perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="email-scroll-area scroll-area mt-1"
      >

        <b-card>
          <b-tabs>
            <b-tab
              :title="project.name"
              title-link-class="projecttitle"
            >
              <b-row>
                <b-col
                  md="6"
                  sm="12"
                >
                  <b-card
                    border-variant="primary"
                    bg-variant="transparent"
                    class="shadow-none"
                  >
                    <div
                      v-if="project.matches"
                      class="d-flex justify-content-between"
                    >
                      <h5 class="mb-0">
                        Numero di match:
                        <b-badge variant="success">
                          {{ project.matches.length }}
                        </b-badge>
                      </h5>
                    </div>
                  </b-card>
                </b-col>
                <b-col
                  md="6"
                  sm="12"
                >
                  <b-card
                    v-if="project && project.rule !== undefined"
                    border-variant="primary"
                    bg-variant="transparent"
                  >
                    <div class="d-flex justify-content-between">
                      <h5 class="mb-0">
                        Durata Match:
                        <b-badge variant="success">
                          {{ project.matches_duration }}
                        </b-badge>
                      </h5>
                      <b-button
                        variant="flat-primary"
                        size="sm"
                        @click="$bvModal.show('modal-assign-matches-duration-'+project.id)"
                      >
                        <span>Gestisci</span>
                      </b-button>
                    </div>
                    <assign-matches-duration-modal
                      :project="project"
                      @refetch-data="$emit('refetch-data')"
                    />
                  </b-card>
                </b-col>
                <b-col
                  md="6"
                  sm="12"
                >
                  <b-card
                    border-variant="primary"
                    bg-variant="transparent"
                    class="shadow-none"
                  >
                    <div
                      v-if="project.number_of_matches_executed"
                      class="d-flex justify-content-between"
                    >
                      <h5 class="mb-0">
                        Match eseguiti:
                        <b-badge variant="success">
                          {{ project.number_of_matches_executed }}
                        </b-badge>
                      </h5>
                    </div>
                  </b-card>
                </b-col>
                <b-col
                  md="6"
                  sm="12"
                >
                  <b-card
                    border-variant="primary"
                    bg-variant="transparent"
                    class="shadow-none"
                  >
                    <div
                      v-if="project.classes"
                      class="d-flex justify-content-between"
                    >
                      <h5 class="mb-0">
                        Numero di classi:
                        <b-badge variant="success">
                          {{ project.classes.length }}
                        </b-badge>
                      </h5>
                    </div>
                  </b-card>
                </b-col>
                <b-col
                  md="6"
                  sm="12"
                >
                  <b-card
                    border-variant="primary"
                    bg-variant="transparent"
                    class="shadow-none"
                  >
                    <div
                      v-if="project.users_in_project"
                      class="d-flex justify-content-between"
                    >
                      <h5 class="mb-0">
                        Numero totale partecipanti:
                        <b-badge variant="success">
                          {{ project.users_in_project }}
                        </b-badge>
                      </h5>
                    </div>
                  </b-card>
                </b-col>
                <b-col
                  md="6"
                  sm="12"
                >
                  <b-card
                    v-if="project && project.rule !== undefined"
                    border-variant="primary"
                    bg-variant="transparent"
                    class="shadow-none"
                  >
                    <div class="d-flex justify-content-between">
                      <h5 class="mb-0">
                        Gruppo Quiz:
                        <b-badge
                          v-if="project.group_quiz"
                          variant="success"
                        >
                          {{ project.group_quiz.name }}
                        </b-badge>
                        <b-badge
                          v-else
                          variant="warning"
                        >Non assegnato
                        </b-badge>
                      </h5>
                      <b-button
                        variant="flat-primary"
                        size="sm"
                        @click="$bvModal.show('modal-assign-group-quiz-'+project.id)"
                      >
                        <span> {{ project.group_quiz ? 'Riassegna' : 'Assegna' }}</span>
                      </b-button>
                    </div>
                    <assign-group-quiz-modal
                      :project.sync="project"
                      @refetch-data="$emit('refetch-data')"
                    />
                  </b-card>
                </b-col>
                <b-col
                  md="6"
                  sm="12"
                >
                  <b-card
                    v-if="project && project.rule !== undefined"
                    border-variant="primary"
                    bg-variant="transparent"
                    class="shadow-none"
                  >
                    <div class="d-flex justify-content-between">
                      <h5 class="mb-0">
                        Regolamento progetto:
                        <b-badge
                          v-if="project.rule"
                          variant="success"
                        >
                          {{ project.rule.name }}
                        </b-badge>
                        <b-badge
                          v-else
                          variant="warning"
                        >Non assegnato
                        </b-badge>
                      </h5>
                      <b-button
                        variant="flat-primary"
                        size="sm"
                        @click="$bvModal.show('modal-assign-rule-'+project.id)"
                      >
                        <span> {{ project.rule ? 'Riassegna' : 'Assegna' }}</span>
                      </b-button>
                    </div>
                    <assign-rule-modal
                      :project.sync="project"
                      @refetch-data="$emit('refetch-data')"
                    />
                  </b-card>
                </b-col>
                <b-col
                  md="6"
                  sm="12"
                >
                  <b-card
                    v-if="project && project.rule !== undefined"
                    border-variant="primary"
                    bg-variant="transparent"
                    class="shadow-none"
                  >
                    <div class="d-flex justify-content-between">
                      <h5 class="mb-0">
                        Gruppo trainings:
                        <b-badge
                          v-if="project.group_file.training"
                          variant="success"
                        >
                          {{ project.group_file.training.name }}
                        </b-badge>
                        <b-badge
                          v-else
                          variant="warning"
                        >Non assegnato
                        </b-badge>
                      </h5>
                      <b-button
                        variant="flat-primary"
                        size="sm"
                        @click="$bvModal.show('modal-assign-group-file-training-'+project.id)"
                      >
                        <span> {{
                          project.group_file.training ? 'Riassegna' : 'Assegna'
                        }}</span>
                      </b-button>
                    </div>
                    <assign-group-file-modal
                      argument="training"
                      :project.sync="project"
                      @refetch-data="$emit('refetch-data')"
                    />
                  </b-card>
                </b-col>
                <b-col
                  md="6"
                  sm="12"
                >
                  <b-card
                    v-if="project && project.rule !== undefined"
                    border-variant="primary"
                    bg-variant="transparent"
                    class="shadow-none"
                  >
                    <div class="d-flex justify-content-between">
                      <h5 class="mb-0">
                        Gruppo Pressrooms:
                        <b-badge
                          v-if="project.group_file.pressroom"
                          variant="success"
                        >
                          {{ project.group_file.pressroom.name }}
                        </b-badge>
                        <b-badge
                          v-else
                          variant="warning"
                        >Non assegnato
                        </b-badge>
                      </h5>
                      <b-button
                        variant="flat-primary"
                        size="sm"
                        @click="$bvModal.show('modal-assign-group-file-pressroom-'+project.id)"
                      >
                        <span> {{
                          project.group_file.pressroom ? 'Riassegna' : 'Assegna'
                        }}</span>
                      </b-button>
                    </div>
                    <assign-group-file-modal
                      argument="pressroom"
                      :project.sync="project"
                      @refetch-data="$emit('refetch-data')"
                    />
                  </b-card>
                </b-col>
                <b-col
                  md="6"
                  sm="12"
                >
                  <b-card
                    v-if="project && project.rule !== undefined"
                    border-variant="primary"
                    bg-variant="transparent"
                    class="shadow-none"
                  >
                    <div class="d-flex justify-content-between">
                      <h5 class="mb-0">
                        Invia Email:
                      </h5>
                      <b-button
                        variant="flat-primary"
                        size="sm"
                        @click="$bvModal.show('modal-send-email')"
                      >
                        <span>Gestisci</span>
                      </b-button>
                    </div>
                    <send-emails-modal
                      :project.sync="project"
                      @refetch-data="$emit('refetch-data')"
                    />
                  </b-card>
                </b-col>
                <b-col
                  md="6"
                  sm="12"
                >
                  <b-card
                    border-variant="primary"
                    bg-variant="transparent"
                    class="shadow-none"
                  >
                    <div class="d-flex justify-content-between">
                      <h5 class="mb-0">
                        Scarica i reports (classifica a presenze):
                      </h5>
                      <b-button
                        variant="flat-danger"
                        size="sm"
                        :disabled="loading"
                        @click="handleDownloadReport"
                      >
                        <b-spinner
                          v-if="loading"
                          small
                        />
                        <feather-icon
                          v-else
                          icon="DownloadIcon"
                          class="mr-50"
                        />
                        <span>Scarica</span>
                      </b-button>
                    </div>
                  </b-card>
                </b-col>
                <b-col
                  md="6"
                  sm="12"
                >
                  <b-card
                    v-if="project && project.rule !== undefined"
                    border-variant="danger"
                    bg-variant="transparent"
                    class="shadow-none bg-danger"
                  >
                    <div class="d-flex justify-content-between">
                      <h5 class="mb-0 text-white">
                        Algoritmo di selezione utenti:
                      </h5>
                      <b-button
                        variant="flat-primary"
                        size="sm"
                        class="text-white"
                        @click="$bvModal.show('modal-assign-type-of-users-selection')"
                      >
                        <span>Gestisci</span>
                      </b-button>
                    </div>
                    <AssignTypeOfUsersSelectionModal :project="project" />
                  </b-card>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab
              v-if="project.group_file"
              title="Trainings"
            >
              <assigned-files-manager
                argument="training"
                :project="project"
                @refetch-data="$emit('refetch-data')"
              />
            </b-tab>
            <b-tab
              v-if="project.group_file"
              title="Pressrooms"
            >
              <assigned-files-manager
                argument="pressroom"
                :project="project"
                @refetch-data="$emit('refetch-data')"
              />
            </b-tab>
            <b-tab title="Lista Utenti">

              <users-list-table
                v-if="project.id"
                :project="project"
              />

            </b-tab>
          </b-tabs>
        </b-card>
      </vue-perfect-scrollbar>
    </div>
    <!--    </b-overlay>-->
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BBadge, BButton, BTabs, BTab, BSpinner,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import AssignRuleModal from '@/views/project/project-show/AssignRuleModal.vue'
import AssignGroupFileModal from '@/views/project/project-show/AssignGroupFileModal.vue'
import AssignGroupQuizModal from '@/views/project/project-show/AssignGroupQuizModal.vue'
import AssignedFilesManager from '@/views/project/project-show/AssignedFilesManager.vue'
import { avatarText } from '@core/utils/filter'
import UsersListTable from '@/views/project/project-show/UsersListTable.vue'
import SendEmailsModal from '@/views/project/project-show/SendEmailsModal.vue'
import { ref } from '@vue/composition-api'
import useProject from '@/views/project/useProject'
import AssignTypeOfUsersSelectionModal from '@/views/project/project-show/AssignTypeOfUsersSelectionModal.vue'
import AssignMatchesDurationModal from '@/views/project/project-show/AssignMatchesDurationModal.vue'

export default {
  name: 'ProjectInfo',
  components: {
    AssignMatchesDurationModal,
    AssignTypeOfUsersSelectionModal,
    SendEmailsModal,
    UsersListTable,
    AssignedFilesManager,
    AssignGroupFileModal,
    AssignGroupQuizModal,
    AssignRuleModal,

    // BSV
    BRow,
    BCol,
    BCard,
    BBadge,
    BButton,
    BTabs,
    BTab,
    BSpinner,

    VuePerfectScrollbar,
  },
  props: {
    project: {
      type: [Array, Object],
      required: true,
    },
    meta: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  setup(props) {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    const loading = ref(false)

    const fields = [
      {
        key: 'name',
        label: 'Nome',
      },
      {
        key: 'number_of_matches',
        label: 'Matches',
      },
      {
        key: 'match_duration',
        label: 'Durata Match',
      },
      {
        key: 'users',
        label: 'Utenti',
      },
      {
        key: 'updated_at',
        label: 'Aggiornato il',
      },
    ]

    const usersInProjectList = () => {
      if (!props.project.classes) return []
      const users = []
      props.project.classes.forEach(classe => {
        classe.users.forEach(user => users.push(user))
      })
      return users
    }

    const {
      downloadReport,
    } = useProject()

    const handleDownloadReport = () => {
      loading.value = true
      downloadReport({ project_id: props.project.id })
        .finally(() => {
          loading.value = false
        })
    }

    return {
      loading,

      // UI
      perfectScrollbarSettings,
      fields,
      usersInProjectList,

      avatarText,

      handleDownloadReport,
    }
  },
}
</script>

<style>
.list-group-item {
    transition: all 1s
}

.projecttitle, .projecttitle.active {
    color: red !important;
}
</style>
