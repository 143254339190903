<template>
  <!-- modal -->
  <b-modal
    id="modal-assign-type-of-users-selection"
    ref="modal-assign-type-of-users-selection"
    :title="'Assegna tipo di selezione utenti al progetto '+project.name"
    ok-title="Salva"
    cancel-title="Chiudi"
    cancel-variant="outline-secondary"
    size="lg"
    centered
    @show="resetModal"
    @hidden="resetModal"
    @ok="handleOk"
  >
    <b-overlay
      id="overlay-background"
      :show="show"
      variant="transparent"
      :opacity="0.85"
      blur="2px"
      rounded="sm"
    >
      <validation-observer ref="assignTypeOfUsersSelection">
        <form
          ref="form"
          class="repeater-form"
          @submit.stop.prevent="validationForm"
        >

          <b-row>
            <b-col
              lg="12"
              md="12"
              sm="12"
              cols="12"
            >
              <b-alert
                v-if="sendData.type_of_match_selection"
                variant="warning"
                show
              >
                <h4 class="alert-heading">
                  Attenzione
                </h4>
                <div class="alert-body">
                  <span>Ogni modifica verrà applicata dal prossimo match e non per quelli in corso in questo momento</span>
                </div>
              </b-alert>

              <b-alert
                v-if="sendData.type_of_match_selection"
                variant="dark"
                show
              >
                <h4 class="alert-heading">
                  Tipo {{ sendData.type_of_match_selection }}
                </h4>
                <div class="alert-body">
                  <span>{{ project.meta.type_of_users_selection.find(type => type.type == sendData.type_of_match_selection).description }}</span>
                </div>
              </b-alert>

              <validation-provider
                #default="validationContext"
                name="name"
                rules="required"
              >
                <b-form-group
                  label="Tipo"
                  label-for="name"
                  invalid-feedback="Obbligatorio"
                >
                  <v-select
                    v-model="sendData.type_of_match_selection"
                    :state="getValidationState(validationContext)"
                    :reduce="val => val.value"
                    :options="resolveDataForVSelect()"
                    :clearable="false"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </form>
      </validation-observer>
    </b-overlay>
  </b-modal>
</template>

<script>
import {
  BFormGroup, BModal, VBModal, BRow, BCol, BOverlay, BAlert,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ref } from '@vue/composition-api'
import store from '@/store'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import {
  required, alphaNum, integer, digits,
} from '@validations'
import vSelect from 'vue-select'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useQuizList from '@/views/quiz/useQuizList'

export default {
  name: 'AssignTypeOfUsersSelectionModal',

  components: {
    ValidationProvider,
    ValidationObserver,
    BFormGroup,
    BModal,
    BRow,
    BCol,
    BOverlay,
    BAlert,
    vSelect,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    project: {
      type: [Object, Array],
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      integer,
      digits,
    }
  },
  methods: {

    validationForm() {
      this.$refs.assignTypeOfUsersSelection.validate()
        .then(success => {
          if (success) {
            this.handleSubmit()
          }
        })
    },
    resetModal() {
      // this.resetSendData()
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.validationForm()
    },
    handleSubmit() {
      this.onSubmit()
    },
  },
  setup(props, {
    emit,
    refs,
  }) {
    // Use toast
    const toast = useToast()

    const show = ref(false)

    const blankSendData = {
      id: props.project.id,
      type_of_match_selection: props.project.type_of_match_selection,
    }

    const sendData = ref(JSON.parse(JSON.stringify(blankSendData)))
    const resetSendData = () => {
      sendData.value = JSON.parse(JSON.stringify(blankSendData))
    }

    const onSubmit = () => {
      show.value = !show.value
      store.dispatch('project/updateProject', sendData.value)
        .then(() => {
          emit('refetch-data')
          refs['modal-assign-type-of-users-selection'].toggle('#toggle-btn')
          show.value = false
        })
        .catch(error => {
          Object.values(error.response.data)
            .forEach(message => {
              toast({
                component: ToastificationContent,
                props: {
                  title: message[0],
                  icon: 'AlertTriangleIcon',
                  variant: 'warning',
                },
              })
            })
          show.value = false
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetSendData)

    const {
      fetchGroupQuiz,
      getListGroupQuiz,
    } = useQuizList()
    fetchGroupQuiz()

    const resolveDataForVSelect = () => props.project.meta.type_of_users_selection.map(data => ({
      label: `${data.type}`,
      value: data.type,
    }))

    return {
      getListGroupQuiz,
      resolveDataForVSelect,
      show,

      sendData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
      resetSendData,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
