import { useToast } from 'vue-toastification/composition'
import { computed, ref, watch } from '@vue/composition-api'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useProject() {
  // Use toast
  const toast = useToast()

  const refProjectsListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    {
      key: 'name',
      label: 'nome',
      sortable: true,
    },
    {
      key: 'rule',
      label: 'regolamento',
      sortable: true,
    },
    {
      key: 'expiration',
      label: 'scadenza',
      sortable: false,
    },
    {
      key: 'created_at',
      label: 'creato il',
      sortable: false,
    },
    {
      key: 'updated_at',
      label: 'aggiornato il',
      sortable: false,
    },
    { key: 'azioni' },
  ]

  // Table Handlers
  const perPage = ref(25)
  const totalElements = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localDataCount = refProjectsListTable.value ? refProjectsListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localDataCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localDataCount,
      of: totalElements.value,
    }
  })

  const fetchProjects = (ctx, callback) => {
    store
      .dispatch('project/fetchProjects', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
      })
      .then(response => {
        const { data, meta } = response.data
        if (callback) {
          callback(data.projects)
        }
        totalElements.value = meta.total
      })
      .catch(error => {
        console.log(error.response)
        toast({
          component: ToastificationContent,
          props: {
            title: 'Problemi nel caricamento pressroom',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const fetchClasses = (ctx, callback, data) => store
    .dispatch('project/fetchClasses', data)
    .catch(error => {
      console.log(error.response)
      toast({
        component: ToastificationContent,
        props: {
          title: 'Problemi nel caricamento',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })

  const fetchUsers = data => store.dispatch('project/fetchUsers', data)
    .catch(error => {
      console.log(error.response)
      toast({
        component: ToastificationContent,
        props: {
          title: 'Problemi nel caricamento',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })

  const fetchClass = data => store.dispatch('project/fetchClass', data)
    .catch(error => {
      console.log(error.response)
      toast({
        component: ToastificationContent,
        props: {
          title: 'Problemi nel caricamento della classe',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })

  const refetchData = () => {
    if (refProjectsListTable.value) {
      refProjectsListTable.value.refresh()
    } else {
      fetchProjects()
    }
  }

  const downloadReport = data => store.dispatch('project/downloadReport', data)
    .then(response => window.open(response.data.data, '_blank'))
    .catch(error => {
      console.log(error.response)
      toast({
        component: ToastificationContent,
        props: {
          title: 'Problemi nel download del report',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })

  const fetchTemplates = data => store.dispatch('project/fetchTemplates', data)
    .catch(error => {
      console.log(error.response)
      toast({
        component: ToastificationContent,
        props: {
          title: 'Problemi nel caricamento dei templates',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })

  const sendEmail = data => store.dispatch('project/sendEmail', data)
    .catch(error => {
      console.log(error.response)
      toast({
        component: ToastificationContent,
        props: {
          title: 'Problemi nel invio delle email',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })

  const getListProject = () => store.getters['project/getListProjects']
  const getCurrentProject = () => store.getters['project/getCurrentProject']
  const getTemplates = () => store.getters['project/getTemplates']

  const projectMenu = [
    { title: 'Progetto', icon: 'SquareIcon', route: { name: 'progetto-show' } },
    { title: 'Classi', icon: 'UsersIcon', route: { name: 'progetto-show-folder', params: { folder: 'classi' } } },
    { title: 'Matches', icon: 'EditIcon', route: { name: 'progetto-show-folder', params: { folder: 'matches' } } },
  ]

  const projectSecondMenu = [
    { title: 'Personal', color: 'success', route: { name: 'progetto-show-label', params: { label: 'personal' } } },
    { title: 'Company', color: 'primary', route: { name: 'progetto-show-label', params: { label: 'company' } } },
    { title: 'Important', color: 'warning', route: { name: 'progetto-show-label', params: { label: 'important' } } },
    { title: 'Private', color: 'danger', route: { name: 'progetto-show-label', params: { label: 'private' } } },
  ]

  const resolveLabelColor = label => {
    if (label === 'personal') return 'success'
    if (label === 'company') return 'primary'
    if (label === 'important') return 'warning'
    if (label === 'private') return 'danger'
    return 'secondary'
  }

  const typeResolver = currentType => {
    if (currentType === 'file' || currentType === 'File') return 'success'
    if (currentType === 'video' || currentType === 'Video') return 'info'
    return 'secondary' // Se archived
  }

  return {
    refProjectsListTable,
    tableColumns,
    perPage,
    totalElements,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    dataMeta,

    fetchProjects,
    fetchClasses,
    fetchUsers,
    fetchClass,
    refetchData,
    downloadReport,
    fetchTemplates,
    sendEmail,

    getListProject,
    getCurrentProject,
    getTemplates,
    projectSecondMenu,
    projectMenu,
    resolveLabelColor,
    typeResolver,
  }
}
