import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'

export default function useUsersList() {
  // Use toast
  const toast = useToast()

  const refQuizListTable = ref(null)

  // Table Handlers
  const perPage = ref(250)
  const totalQuizzes = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const groupQuizId = ref(null)
  const getGroup = ref(true)

  const dataMeta = computed(() => {
    const localUsersCount = refQuizListTable.value ? refQuizListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localUsersCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localUsersCount,
      of: totalQuizzes.value,
    }
  })

  const fetchQuiz = (ctx, callback) => {
    store
      .dispatch('quizzes/fetchQuizzes', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        groupQuizId: groupQuizId.value,
      })
      .then(response => {
        const { data, meta } = response.data
        if (callback) {
          callback(data.quiz)
        }
        totalQuizzes.value = meta.total
      })
      .catch(error => {
        console.log(error.response)
        toast({
          component: ToastificationContent,
          props: {
            title: 'Problemi nel caricamento quiz',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const fetchGroupQuiz = () => {
    store
      .dispatch('quizzes/fetchGroupQuizzes', {
        getGroup: getGroup.value,
      })
      .then()
      .catch(error => {
        console.log(error.response)
        toast({
          component: ToastificationContent,
          props: {
            title: 'Problemi nel caricamento training',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const refetchData = () => {
    if (refQuizListTable.value) {
      refQuizListTable.value.refresh()
    } else {
      fetchQuiz()
      fetchGroupQuiz()
    }
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })

  const refetchQuiz = () => {
    store.dispatch('quizzes/fetchQuiz', { id: router.currentRoute.params.id })
      .then(() => {
      })
      .catch(error => {
        console.log(error.response)
        toast({
          component: ToastificationContent,
          props: {
            title: 'Problemi nel caricamento utenti',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const getListQuiz = () => store.getters['quizzes/getListQuiz']
  const getListGroupQuiz = () => store.getters['quizzes/getListGroupQuiz']

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const statusResolver = currentStatus => {
    if (currentStatus === 'Pending') return 'secondary'
    if (currentStatus === 'To Pay') return 'warning'
    if (currentStatus === 'Payed') return 'light-success'
    if (currentStatus === 'Sended') return 'success'
    return 'secondary' // Se archived
  }

  const statusNameResolver = currentStatusName => {
    if (currentStatusName === 'Pending') return 'In Sospeso'
    if (currentStatusName === 'To Pay') return 'Da Pagare'
    if (currentStatusName === 'Payed') return 'Pagato'
    if (currentStatusName === 'Sended') return 'Spedito'
    return 'secondary' // Se archived
  }

  return {
    // Options

    //
    fetchQuiz,
    fetchGroupQuiz,
    refetchQuiz,
    getListQuiz,
    getListGroupQuiz,
    perPage,
    currentPage,
    totalQuizzes,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    groupQuizId,
    refQuizListTable,
    refetchData,

    // Extra Filters
    //
    statusResolver,
    statusNameResolver,

  }
}
