<template>
  <div style="height: inherit">
    <div class="email-app-list">
      <vue-perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="email-user-list scroll-area"
      >
        <ul
          v-if="matches"
          class="email-media-list"
        >
          <b-media
            v-for="match in matches"
            :key="match.id"
            tag="li"
            no-body
          >
            <b-media-aside class="media-left mr-50">
              <div class="user-action">
                <div class="email-favorite">
                  {{ match.id }}
                </div>
              </div>
            </b-media-aside>

            <b-media-body>
              <div
                class="mail-details"
              >
                <div class="mail-items d-flex">
                  <h5
                    class="mb-25"
                    @click="updateViewData(match)"
                  >
                    Inizierà il: {{ match.start_times ? match.start_times : 'Non assegnato' }}
                  </h5>
                  <b-button
                    v-if="!match.started_at"
                    variant="flat-primary"
                    size="sm"
                    @click="$bvModal.show('modal-assign-start-time-'+match.id)"
                  >
                    <span> {{ match.start_times ? 'Riassegna' : 'Assegna' }}</span>
                  </b-button>
                  <assign-start-time-modal
                    :match.sync="match"
                    @refetch-data="$emit('refetch-data')"
                  />
                  <span class="text-truncate" />

                </div>
                <div class="mail-meta-item">
                  <span class="mail-date">Ultimo di aggiornamento: {{ match.updated_at }}</span>
                </div>
              </div>

              <div class="mail-message">
                <p class="text-truncate mb-0">
                  Quiz: {{ match.quiz ? match.quiz.name : 'Non associato' }}
                  <b-button
                    v-if="!match.started_at"
                    variant="flat-primary"
                    size="sm"
                    @click="$bvModal.show('modal-assign-quiz-'+match.id)"
                  >
                    <span> {{ match.quiz ? 'Riassegna' : 'Assegna' }}</span>
                  </b-button>
                </p>
              </div>
              <assign-quiz-modal
                :match.sync="match"
                :project.sync="project"
                :quizzes="getQuizzes"
                @refetch-data="$emit('refetch-data')"
              />
            </b-media-body>
          </b-media>
        </ul>
        <div
          class="no-results"
        >
          <h5>Nessun match trovato</h5>
        </div>
      </vue-perfect-scrollbar>
    </div>
    <!-- Email View/Detail -->
    <match-view
      :class="{'show': showView}"
      :view-data="viewData"
      @close-view="showView = false"
    />
  </div>
</template>

<script>
import {
  BMedia, BMediaAside, BMediaBody, BButton,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { computed, ref } from '@vue/composition-api'
import MatchView from '@/views/project/project-show/MatchView.vue'
import Ripple from 'vue-ripple-directive'
import AssignQuizModal from '@/views/project/project-show/AssignQuizModal.vue'
import AssignStartTimeModal from '@/views/project/project-show/AssignStartTimeModal.vue'
import useQuizList from '@/views/quiz/useQuizList'

export default {
  name: 'MatchesList',
  components: {
    AssignQuizModal,
    AssignStartTimeModal,
    MatchView,
    BMedia,
    BMediaAside,
    BMediaBody,
    BButton,

    // 3rd Party
    VuePerfectScrollbar,
  },
  directives: {
    Ripple,
  },
  props: {
    matches: {
      type: Array,
      required: true,
    },
    project: {
      type: [Object, Array],
      required: true,
    },
  },
  setup(props) {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 1000,
    }

    const {
      totalQuizzes,
      fetchQuiz,
      getListQuiz,
      groupQuizId,
    } = useQuizList()
    console.log(totalQuizzes.value)
    groupQuizId.value = props.project.group_quiz?.id
    fetchQuiz()

    const getQuizzes = computed(() => getListQuiz())

    const showView = ref(false)
    const viewData = ref({})
    const updateViewData = data => {
      viewData.value = data
      showView.value = true
    }

    const showAssignQuiz = ref(false)

    return {
      getQuizzes,
      // UI
      perfectScrollbarSettings,
      showView,
      viewData,
      updateViewData,
      showAssignQuiz,
    }
  },
}
</script>
