import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'

export default function useRuleList() {
  // Use toast
  const toast = useToast()

  const refRuleListTable = ref(null)

  // Table Handlers
  const perPage = ref(25)
  const totalRules = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localUsersCount = refRuleListTable.value ? refRuleListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localUsersCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localUsersCount,
      of: totalRules.value,
    }
  })

  const fetchRules = (ctx, callback) => {
    store
      .dispatch('rules/fetchRules', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
      })
      .then(response => {
        const { data, meta } = response.data
        if (callback) {
          callback(data.rules)
        }
        totalRules.value = meta.total
      })
      .catch(error => {
        console.log(error.response)
        toast({
          component: ToastificationContent,
          props: {
            title: 'Problemi nel caricamento regole',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const refetchData = () => {
    if (refRuleListTable.value) {
      refRuleListTable.value.refresh()
    } else {
      fetchRules()
    }
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })

  const refetchRule = () => {
    store.dispatch('rules/fetchRule', { id: router.currentRoute.params.id })
      .then(() => {
      })
      .catch(error => {
        console.log(error.response)
        toast({
          component: ToastificationContent,
          props: {
            title: 'Problemi nel caricamento utenti',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const getListRules = () => store.getters['rules/getListRules']

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const statusResolver = currentStatus => {
    if (currentStatus === 'Pending') return 'secondary'
    if (currentStatus === 'To Pay') return 'warning'
    if (currentStatus === 'Payed') return 'light-success'
    if (currentStatus === 'Sended') return 'success'
    return 'secondary' // Se archived
  }

  const statusNameResolver = currentStatusName => {
    if (currentStatusName === 'Pending') return 'In Sospeso'
    if (currentStatusName === 'To Pay') return 'Da Pagare'
    if (currentStatusName === 'Payed') return 'Pagato'
    if (currentStatusName === 'Sended') return 'Spedito'
    return 'secondary' // Se archived
  }

  return {
    // Options

    //
    fetchRules,
    refetchRule,
    getListRules,
    perPage,
    currentPage,
    totalRules,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refRuleListTable,
    refetchData,

    // Extra Filters
    //
    statusResolver,
    statusNameResolver,

  }
}
